<template>
  <div class="page about">
    <section class="banner">
      <div class="container">
        <h2 v-text="$t('About us')"></h2>
      </div>
    </section>
    <section class="page__content">
      <div class="container">
        <div class="about__row">
          <div class="about__card">
            <div>
              <h3>
                <span
                  class="about__title"
                  v-text="$t('Connecting readers to')"
                ></span
                ><br />
                <span
                  class="about__subtitle"
                  v-text="$t('the book they have in mind')"
                ></span>
              </h3>
              <p v-html="$t('Book in mind (description)')"></p>
            </div>
          </div>
          <div class="about__image">
            <img src="@/assets/img/about_mind.svg" alt="$t('Book in mind')" />
          </div>
        </div>

        <div class="about__row">
          <div class="about__image">
            <img
              src="@/assets/img/about_something_new.svg"
              alt="$t('Something new')"
            />
          </div>
          <div class="about__card">
            <div>
              <h3>
                <span class="about__title" v-text="$t('This is about')"></span
                >&nbsp;
                <span
                  class="about__subtitle"
                  v-text="$t('creating something new')"
                ></span>
              </h3>
              <p v-html="$t('Something new (description)')"></p>
            </div>
          </div>
        </div>

        <div class="about__row">
          <div class="about__card">
            <div>
              <h3>
                <span class="about__title" v-text="$t('This is about')"></span
                >&nbsp;
                <span
                  class="about__subtitle"
                  v-text="$t('creating a community')"
                ></span>
              </h3>
              <p v-html="$t('Creating a community (description)')"></p>
            </div>
          </div>
          <div class="about__image">
            <img src="@/assets/img/about_community.svg" alt="$t('Community')" />
          </div>
        </div>

        <div class="about__row">
          <div class="about__image">
            <img src="@/assets/img/about_oblivion.svg" alt="$t('Oblivion')" />
          </div>
          <div class="about__card">
            <div>
              <h3>
                <span class="about__title" v-text="$t('This is about')"></span
                >&nbsp;
                <span
                  class="about__subtitle"
                  v-text="$t('saving books from the oblivion')"
                ></span>
              </h3>
              <p
                v-html="$t('Saving books from the oblivion (description)')"
              ></p>
            </div>
          </div>
        </div>

        <div class="about__row">
          <div class="about__card">
            <div>
              <h3>
                <span class="about__title" v-text="$t('This is about')"></span
                >&nbsp;
                <span
                  class="about__subtitle"
                  v-text="$t('an ancient story')"
                ></span>
              </h3>
              <p
                v-html="$t('This is about an ancient story (description)')"
              ></p>
            </div>
          </div>
          <div class="about__image">
            <img
              src="@/assets/img/about_history.svg"
              alt="$t('Ancient story')"
            />
          </div>
        </div>
      </div>

      <search-advance />
    </section>
  </div>
</template>

<script>
import SearchAdvance from '../home/searchAdvance/SearchAdvance';

export default {
  name: 'About',

  components: {
    SearchAdvance,
  },
};
</script>

<i18n src="./translations.json"></i18n>

<style lang="scss">
@import './about.scss';
</style>
